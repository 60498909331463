import { useState, useEffect } from "react";

const useModal = () => {
  const [isOpen, setIsOpen] = useState(true); // Show the modal on page load
  const [isUserActive, setIsUserActive] = useState(true); // Assume user is initially active

  useEffect(() => {
    let inactivityTimer;

    const resetInactivityTimer = () => {
      clearTimeout(inactivityTimer);
      inactivityTimer = setTimeout(() => {
        setIsOpen(true);
      }, 30000); // 30 seconds
    };

    const handleUserActivity = () => {
      setIsUserActive(true);
      resetInactivityTimer();
    };

    const handleInactivity = () => {
      setIsUserActive(false);
    };

    // Listeners for user activity
    window.addEventListener("mousemove", handleUserActivity);
    window.addEventListener("keydown", handleUserActivity);
    window.addEventListener("scroll", handleUserActivity);
    window.addEventListener("click", handleUserActivity);

    // Listener for inactivity
    window.addEventListener("mousemove", handleInactivity);
    window.addEventListener("keydown", handleInactivity);
    window.addEventListener("scroll", handleInactivity);
    window.addEventListener("click", handleInactivity);

    return () => {
      clearTimeout(inactivityTimer);

      // Remove all event listeners on cleanup
      window.removeEventListener("mousemove", handleUserActivity);
      window.removeEventListener("keydown", handleUserActivity);
      window.removeEventListener("scroll", handleUserActivity);
      window.removeEventListener("click", handleUserActivity);

      window.removeEventListener("mousemove", handleInactivity);
      window.removeEventListener("keydown", handleInactivity);
      window.removeEventListener("scroll", handleInactivity);
      window.removeEventListener("click", handleInactivity);
    };
  }, []);

  const closeModal = () => {
    setIsOpen(false);
    setIsUserActive(true); // Reset user activity state when closing modal
  };

  return { isOpen, closeModal, isUserActive };
};

export default useModal;
