import React, { useEffect, lazy, Suspense } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Navbar from '../NavBar/navbar'
import Modal from '../Modal/Modal'
import useModal from '../Modal/useModal'
import { BookingProvider } from '../BookingContext/DataContext'
import BookingLoader from '../BookingLoader/LoadingAndError'

// Lazy loaded components
const Home = lazy(() => import('../Home/home'))
const UserSubscription = lazy(() =>
  import('../UserSubscription/UserSubscription')
)
const Quote = lazy(() => import('../Booking/Quote/Quote'))
const Services = lazy(() => import('../Services/Services'))
const ImageDesc = lazy(() => import('../ImageDesc/imageDesc'))
const Team = lazy(() => import('../OurTeam/Team'))
const VideoDesc = lazy(() => import('../VideoDesc/videoDesc'))
const Testimonial = lazy(() => import('../Testimonials/testimonial'))
const FAQs = lazy(() => import('../FAQs/FAQs'))
const NewsLetter = lazy(() => import('../NewsLetter/news'))
const Find = lazy(() => import('../FindUs/Find'))
const Footer = lazy(() => import('../Footer/footer'))
const ProductOption = lazy(() =>
  import('../Booking/ProductOption/ProductOption')
)
const BookingDetail = lazy(() =>
  import('../Booking/BookingDetail/BookingDetail')
)
const PaymentMethods = lazy(() =>
  import('../Booking/PaymentMethods/PaymentMethods')
)
const CardDetails = lazy(() => import('../Booking/CardDetails/CardDetails'))
const CardPayment = lazy(() => import('../Booking/CardPayment/CardPayment'))
const ReturnURL = lazy(() => import('../Booking/PaymentURL/ReturnURL'))
const CancelURL = lazy(() => import('../Booking/PaymentURL/CancelURL'))
const AboutUs = lazy(() => import('../Booking/About US/AboutUs'))
const TermsAndConditions = lazy(() =>
  import('../Booking/TermsAndConditions/TermsAndConditions')
)
const MergePrivacyComponent = lazy(() => import('./MergePrivacyComponent'))
const MergeTermsComponents = lazy(() => import('./MergeTermsComponents'))

function App() {
  return (
    <BookingProvider>
      <Router>
        <Routes>
          <Route path="/" element={<AllComponentsWithRedirect />} />
          <Route
            path="/product_option"
            element={<SuspenseFallbackWrapper component={<ProductOption />} />}
          />
          <Route
            path="/booking_detail"
            element={<SuspenseFallbackWrapper component={<BookingDetail />} />}
          />
          <Route
            path="/payment_method"
            element={<SuspenseFallbackWrapper component={<PaymentMethods />} />}
          />
          <Route
            path="/card_detail"
            element={<SuspenseFallbackWrapper component={<CardDetails />} />}
          />
          <Route
            path="/card_payment"
            element={<SuspenseFallbackWrapper component={<CardPayment />} />}
          />
          <Route
            path="/return-url"
            element={<SuspenseFallbackWrapper component={<ReturnURL />} />}
          />
          <Route
            path="/cancel-url"
            element={<SuspenseFallbackWrapper component={<CancelURL />} />}
          />
          <Route
            path="/terms-and-conditions"
            element={
              <SuspenseFallbackWrapper component={<TermsAndConditions />} />
            }
          />
          <Route
            path="/privacy-policy"
            element={
              <SuspenseFallbackWrapper component={<MergePrivacyComponent />} />
            }
          />
          <Route
            path="/terms-conditions"
            element={
              <SuspenseFallbackWrapper component={<MergeTermsComponents />} />
            }
          />
          {/* <Route path="/product_option" element={<ProductOption />} />
          <Route path="/booking_detail" element={<BookingDetail />} />
          <Route path="/payment_method" element={<PaymentMethods />} />
          <Route path="/card_detail" element={<CardDetails />} />
          <Route path="/card_payment" element={<CardPayment />} />
          <Route path="/return-url" element={<ReturnURL />} />
          <Route path="/cancel-url" element={<CancelURL />} />
          <Route path="/about-booking" element={<AboutUs />} />
          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          />
          <Route path="/privacy-policy" element={<MergePrivacyComponent />} />
          <Route path="/terms-conditions" element={<MergeTermsComponents />} /> */}
        </Routes>
      </Router>
    </BookingProvider>
  )
}

function AllComponentsWithRedirect() {
  // Your useFetchAndRedirect logic goes here
  return <AllComponents />
}

function AllComponents() {
  const { isOpen, closeModal } = useModal()

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }, [])

  return (
    <div>
      <Modal isOpen={isOpen} onClose={closeModal} />
      <Navbar />
      <Suspense fallback={<div>Loading Home...</div>}>
        <Home />
      </Suspense>
      <Suspense fallback={<div>Loading Subscription...</div>}>
        <UserSubscription />
      </Suspense>
      <Suspense fallback={<div>Loading Quote...</div>}>
        <Quote />
      </Suspense>
      <Suspense fallback={<div>Loading Services...</div>}>
        <Services />
      </Suspense>
      <Suspense fallback={<div>Loading Image Description...</div>}>
        <ImageDesc />
      </Suspense>
      <Suspense fallback={<div>Loading Team...</div>}>
        <Team />
      </Suspense>
      <Suspense fallback={<div>Loading Video Description...</div>}>
        <VideoDesc />
      </Suspense>
      <Suspense fallback={<div>Loading Testimonials...</div>}>
        <Testimonial />
      </Suspense>
      <Suspense fallback={<div>Loading FAQs...</div>}>
        <FAQs />
      </Suspense>
      <Suspense fallback={<div>Loading Newsletter...</div>}>
        <NewsLetter />
      </Suspense>
      <Suspense fallback={<div>Loading Find Us...</div>}>
        <Find />
      </Suspense>
      <Suspense fallback={<div>Loading Footer...</div>}>
        <Footer />
      </Suspense>
    </div>
  )
}

// Helper component to simplify Suspense wrappers
function SuspenseFallbackWrapper({ component }) {
  return <Suspense fallback={<BookingLoader />}>{component}</Suspense>
}

export default App
