import './App.css'
import './index.css'
import AllComponents from './Components/MergeAllComponents/AllComponents'
import useFetchAndRedirect from './Components/BookingContext/useFetchAndRedirect'

export default function App() {
  return (
    <div>
      <AllComponents />
    </div>
  )
}
