import React from 'react'
import './LoadingAndError.css'

const BookingLoader = () => {
  return (
    <div className="modal-overlay ">
      <div className="spinner "></div>
    </div>
  )
}

export default BookingLoader
