import React from 'react'
import './Modal.css'
import { NavLink } from 'react-router-dom'
import modal_video from './../../assets/modal-video-for-add.mp4'
import { API_DASHBOARD_DOMAIN } from '../../Config/Config'

const Modal = ({ isOpen, onClose }) => {
  if (!isOpen) return null

  return (
    <div className="modal-overlay">
      <div className="modal">
        <div className="modal-video">
          <video width="100%" autoPlay loop muted>
            <source src={modal_video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <div className="modal-ftr">
            <div className="modal-btn">
              <a
                className={'modal-nav-btn'}
                href={`${API_DASHBOARD_DOMAIN}/signup`}
              >
                SignUp
              </a>
            </div>
          </div>
        </div>
        <button className="modal-close" onClick={onClose}></button>
      </div>
    </div>
  )
}

export default Modal
